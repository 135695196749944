import React, { FC, HTMLProps, TextareaHTMLAttributes } from "react";
import cn from "classnames";

type Props = {
	error?: boolean;
	errorText?: string;
	rootProps?: HTMLProps<HTMLDivElement>;
	textAreaProps?: TextareaHTMLAttributes<HTMLTextAreaElement>;
};

const TextArea: FC<Props> = (props) => {
	const { error = false, errorText = "", rootProps, textAreaProps } = props;

	return (
		<div {...rootProps}>
			<span className="inline-block w-full p-[1px] bg-gradient-to-t to-30% from-[#8F90A7] to-[#C2C3D0] hover:to-[#8F90A7] focus-within:to-[#8F90A7]">
				<textarea
					{...textAreaProps}
					className={cn(
						textAreaProps?.className,
						"w-full p-5 mb-[-6px] bg-white outline-none border-none resize-none text-body placeholder:text-body md:placeholder:text-body placeholder:text-gray-02",
						{ "text-[#E41D3E]": error },
					)}
				/>
			</span>
			{error && (
				<p className="mt-2 text-[0.75rem] leading-[0.875rem] tracking-[-0.5%] text-[#E41D3E]">
					{errorText}
				</p>
			)}
		</div>
	);
};

export default TextArea;
