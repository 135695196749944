import React, { FC } from "react";

import { Image } from "@src/components/ui";
import { IComponentExternalCallToAction, IWithKey } from "@src/types/page-components.types";

type Props = {
	content: (IComponentExternalCallToAction & IWithKey)[];
};

const SocialMedia: FC<Props> = (props) => {
	const { content } = props;

	return (
		<ul className="flex items-center mt-6 lg:mt-0">
			{content.map((socialMedia) => {
				const { _key, url, startIcon, label, endIcon, id } = socialMedia;

				return (
					<li key={_key} className="mr-3 last:mr-0">
						<a
							{...(id !== null && { id })}
							href={url}
							className="inline-block p-3 rounded-full transition-colors duration-200 bg-[#202125] hover:bg-[#28292C]"
						>
							{startIcon !== null && (
								<Image CMSImage={startIcon} alt="social media" style={{ display: "block" }} />
							)}
							<p className="text-[0.8125rem] leading-6 tracking-[-0.5%] font-normal text-[#5B5F66]">
								{label}
							</p>
							{endIcon !== null && (
								<Image CMSImage={endIcon} alt="social media" style={{ display: "block" }} />
							)}
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default SocialMedia;
