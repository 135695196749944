import React, { FC } from "react";

import { Link } from "@src/components/ui";
import { IComponentLinksWithTitle } from "@src/types/page-components.types";
import Title from "../title";

type Props = {
	content: IComponentLinksWithTitle;
};

const Pages: FC<Props> = (props) => {
	const { content } = props;
	const { title, links } = content;

	return (
		<div className="mb-10 lg:mb-0">
			<Title>{title}</Title>
			<ul>
				{links.map((link) => {
					const { _key, label, target } = link;

					return (
						<li key={_key} className="mb-[0.375rem] lg:mb-2 last:mb-0">
							<Link target={target}>
								<span className="text-[0.8125rem] text-white hover:underline">{label}</span>
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Pages;
