import { graphql, useStaticQuery } from "gatsby";

import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";
import {
	IUseLocalizedFooterArguments,
	TUseLocalizedFooterReturn,
} from "./useLocalizedFooter.types";
import { IFooterData } from "../../footer.types";

const useLocalizedFooter = (args: IUseLocalizedFooterArguments): TUseLocalizedFooterReturn => {
	const { locale = DEFAULT_LOCALE } = args;

	const { allSanityFooter } = useStaticQuery(graphql`
		{
			allSanityFooter {
				nodes {
					language
					logo {
						asset {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 75
										placeholder: BLURRED
										breakpoints: [480, 768, 1024, 1440]
										blurredOptions: { toFormat: WEBP }
										webpOptions: { quality: 75 }
									)
								}
								publicURL
								extension
							}
							width
							height
						}
					}
					callToAction {
						startIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						label
						endIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						target {
							_id
							slug
							parentPages {
								_id
								slug
							}
						}
						id
					}
					primaryPages {
						title
						links {
							_key
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
							}
						}
					}
					secondaryPages {
						_key
						title
						links {
							_key
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
							}
						}
					}
					contactsTitle
					contacts {
						_key
						url
						label
					}
					addressesTitle
					addresses {
						_key
						street
						location
						href
					}
					copyright
					privacyPolicy {
						label
						target {
							_id
							slug
							parentPages {
								_id
								slug
							}
						}
					}
					socialMedia {
						_key
						url
						startIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						label
						endIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						id
					}
				}
			}
		}
	`) as IFooterData;

	return allSanityFooter.nodes.find((node) => node.language === locale);
};

export default useLocalizedFooter;
