import React, { FC } from "react";

import { IComponentAddress, IWithKey } from "@src/types/page-components.types";

type Props = {
	content: (IComponentAddress & IWithKey)[];
};

const Addresses: FC<Props> = (props) => {
	const { content } = props;

	return (
		<ul className="mb-12 lg:mb-0">
			{content.map((address) => {
				const { _key, street, location, href } = address;

				return (
					<li key={_key} className="flex mb-[0.375rem] lg:mb-2 last:mb-0">
						<a href={href} target="_blank">
							<p className="text-[0.8125rem] text-white hover:underline">
								{street} {location}
							</p>
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default Addresses;
