import React, { FC, ReactNode } from "react";

type Props = {
	children: ReactNode;
};

const Title: FC<Props> = (props) => {
	const { children } = props;

	return <p className="mb-4 font-medium text-gray-02">{children}</p>;
};

export default Title;
