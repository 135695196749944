import React, { FC } from "react";

import { Addresses, Contacts, Logo, Pages, PrivacyPolicy, SocialMedia, Title } from "./components";
import { useLocalizedFooter } from "./hooks";
import { CallToAction } from "@src/components/ui";
import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";

const Footer: FC = () => {
	const footer = useLocalizedFooter({ locale: DEFAULT_LOCALE });

	if (footer === undefined) return null;

	const {
		logo,
		callToAction,
		primaryPages,
		secondaryPages,
		contactsTitle,
		contacts,
		addressesTitle,
		addresses,
		copyright,
		privacyPolicy,
		socialMedia,
	} = footer;

	return (
		<footer className="mt-auto bg-[#111521]">
			<div className="max-w-screen-xl m-auto pt-14 pb-10 px-4 lg:pb-6 lg:px-14">
				<div className="lg:flex lg:justify-between">
					<Logo content={logo} />
					<div className="lg:flex-grow lg:flex lg:justify-between lg:max-w-[68%] xl:max-w-[61%] mt-12 lg:mt-0">
						<div className="lg:flex-1">
							<Pages content={primaryPages} />
						</div>
						<div className="lg:flex-1 flex flex-col justify-between">
							{secondaryPages.map((secondaryPage) => (
								<Pages key={secondaryPage._key} content={secondaryPage} />
							))}
						</div>
						<div className="lg:flex-1 flex flex-col justify-between">
							<div>
								<Title>{contactsTitle}</Title>
								<Contacts content={contacts} />
							</div>
							<div>
								<Title>{addressesTitle}</Title>
								<Addresses content={addresses} />
							</div>
						</div>
					</div>
				</div>

				<CallToAction
					CMSCallToAction={callToAction}
					className="flex items-center justify-center gap-2 w-full sm:max-w-fit mt-2 lg:-mt-16 py-5 px-10 bg-white rounded-full text-center font-medium"
				/>

				<div className="lg:flex lg:justify-between lg:items-center mt-14 lg:mt-20 pt-5 border-t-[1px] border-[#323337]">
					<PrivacyPolicy content={{ copyright, link: privacyPolicy }} />
					<SocialMedia content={socialMedia} />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
