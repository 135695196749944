import React, { FC } from "react";

import { IComponentExternalLink, IWithKey } from "@src/types/page-components.types";

type Props = {
	content: (IComponentExternalLink & IWithKey)[];
};

const Contacts: FC<Props> = (props) => {
	const { content } = props;

	return (
		<ul className="mb-10 lg:mb-0">
			{content.map((contact) => {
				const { _key, label, url } = contact;

				return (
					<li key={_key} className="mb-[0.375rem] lg:mb-2 last:mb-0">
						<a href={url} className="text-[0.8125rem] text-white hover:underline">
							{label}
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default Contacts;
